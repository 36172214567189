<template>
  <div class="row fadeInUp" role="main">
    <div
      id="page-block"
      class="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2"
    >
      <h5 id="display-title">
        Display
      </h5>
      <div class="card border-0 shadow-sm mb-4">
        <div class="card-body" v-html="displayRendered" />
        <div id="display-footer" class="bg-light card-footer">
          <p class="fs--1 mb-0">
            <strong>Notes: </strong>We really appreciate your business and if
            there’s anything else we can do, please let us know!
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import jsonAPI from "@/api";
import { postToApi } from "@/helpers/apiConnection";

export default {
  name: "OrderView",
  async beforeRouteEnter(to, from, next) {
    const _id = to.params.id;
    const _did = to.params.did;
    const orderResponse = await jsonAPI.get("order/" + _id);
    const displayResponse = await jsonAPI.get("/configurationData/" + _did);
    let template = ";";
    if (orderResponse && displayResponse) {
      const code = displayResponse.data.data.code ?? "";
      const data = JSON.stringify(orderResponse.data);
      template = await postToApi("organization/mail/render", {
        data: data,
        template: code,
      });
    }

    next((vm) => {
      vm.order = orderResponse.data;
      vm.display = displayResponse.data;
      vm.displayRendered = template;
    });
  },
  data() {
    return {
      order: {},
      display: {},
      displayRendered: "",
    };
  },
};
</script>

<style>
@media print {
  #header,
  #limit-notice,
  #display-title,
  #display-footer {
    display: none !important;
  }
  #main-body {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }
  #page-block {
    margin-left: 0 !important;
    max-width: 100% !important;
  }
}
</style>
